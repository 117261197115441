import React from "react";
import AseclaDataContext from "../../store/AseclaDataContext";
import { ContextProps } from "../../type/ContextProps";
import { GetPriceResponse } from "../../type/responses/GetPriceResponse";
import { BonusCode } from "../../type/BonusCode";
import { LicenseType } from "../../type/LicenseType";
import { useTranslation } from "react-i18next";
import { usePrice } from "../../hooks/mutations/usePrice";

interface PriceParams {
    quantity: number,
    length: number,
    licenseType: LicenseType,
    bonusCode?: BonusCode,
    bonusCodeValid?: boolean,
    showHint: boolean,
    showDiscount: boolean,
    includeOrganizationDiscount?: boolean,
    displayNetAndBrutto?: boolean,
    priceToCurrencySpace?: boolean
}
export const VAT_VALUE = 0.23;
function Price({quantity, length, licenseType, bonusCode, bonusCodeValid, showDiscount, includeOrganizationDiscount = true, displayNetAndBrutto = false, priceToCurrencySpace = false}: PriceParams) {
    const {t} = useTranslation();

    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;
    const [ priceResponse, setPriceResponse] = React.useState<GetPriceResponse | null>(null);
    const { getPrice, priceError, priceSuccess } = usePrice(setPriceResponse, includeOrganizationDiscount);

    React.useEffect(() => {
        if (licenseType != null && quantity !== undefined) {
            getPrice({
                bonusCode: (bonusCode != undefined && bonusCodeValid) ? bonusCode.theCode : null,
                length: length,
                lengthUnit: licenseType.lengthUnit,
                licenseTypeId: licenseType.id!,
                numberOfUsers: quantity,
            });
        }
    }, [quantity, length, licenseType, bonusCodeValid, props.currentOrganizationNo]);
    //Don't call on bonusCode change to not make second call in parallel (with outdated value bonusCodeValid)

    if (!priceSuccess || priceResponse == null) {
        return <div className="spinner"></div>
    }
    if (priceError) {
        return <></>
    }

//     <Price length={length!} licenseType={licType!} quantity={quantity!} showHint={false} showDiscount={true}
//     bonusCode={bonusCode ?? undefined} bonusCodeValid={bonusCodeValid} displayNetAndBrutto={true}                        
// ></Price> netto (6023 brutto)

    let showFinalValue = !showDiscount || priceResponse.discountValue === null || priceResponse.discountValue === undefined || (priceResponse.baseValue === priceResponse.discountValue);

    interface PriceCaptionParams {
        price: number
    }
    const PriceCaption = ({price}: PriceCaptionParams) => {
        return <>
            <span className={"finalValue"}>
                {price}
            </span>
            {priceToCurrencySpace && " "}
            <span className={"currency"}>
                {priceResponse.currency}
            </span>
        </>
    }
    interface PriceWithDescriptionParams {
        price: number,
        description?: string
    }
    const PriceWithDescription = ({price}: PriceWithDescriptionParams) => {
        return <>
            <PriceCaption price={price}></PriceCaption>
            {displayNetAndBrutto && <> {"netto"}</>}
            {displayNetAndBrutto && <> ( 
                <PriceCaption price={price * (1 + VAT_VALUE)}></PriceCaption> brutto)
            </>}
        </>
    }

    return <>
        {priceResponse != null && <>
            {showFinalValue && 
                <span className="costDiv">
                    <PriceWithDescription price={priceResponse.discountValue ?? priceResponse.baseValue}></PriceWithDescription>
                </span>
            }
            {(showDiscount && priceResponse.discountValue !== null && priceResponse.discountValue !== undefined && (priceResponse.baseValue !== priceResponse.discountValue)) &&
                <span className="costDiv">
                    <span className={"baseValue"}>
                        <s>
                            <PriceWithDescription price={priceResponse.baseValue}></PriceWithDescription>
                        </s>
                    </span>
                    &nbsp;
                    <PriceWithDescription price={priceResponse.discountValue}></PriceWithDescription>
                </span>
            }
        </>}
    </>
}
export default Price;

import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AseclaAdminOrganizationsPage from "./page/AseclaAdminOrganizationsPage";
import AseclaAdminLicTypePage from "./page/AseclaAdminLicTypePage";
import AseclaAdminOrdersPage from "./page/AseclaAdminOrdersPage";
import AseclaAdminProductsPage from "./page/AseclaAdminProductsPage";
import BonusCodesPage from "./page/BonusCodesPage";
import ConfirmOrderPage from "./page/ConfirmOrderPage";
import HomePage from "./page/Home";
import LicensesByFeaturePage from "./page/LicensesByFeature";
import LicensesByOrderPage from "./page/LicensesByOrders";
import LicensesDetailsPage from "./page/LicensesDetailsPage";
import ManageOrganizationsPage from "./page/ManageOrganizationsPage";
import ManageUsersPage from "./page/ManageUsers";
import { AseclaDataContextProvider } from "./store/AseclaDataContext";
import LicensesListPage from "./page/LicensesListPage";
import AseclaOfferPage from "./page/AseclaOfferPage";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Priviliges, { LicensesRole } from "./component/utils/Priviliges";
import getAuthentication from "./store/AuthenticationPageBase";
import RedeemCodePage from "./page/RedeemCodePage";
import MyRolePage from "./page/MyRolePage";
import ManageOneOrganizationPage from "./page/ManageOneOrganizationPage";
import './App.css';
import TitleBanner from "./component/utils/TitleBanner";

const SecurityRoute = ({ children }: any): any => {
  const authentication = getAuthentication();

  if (!authentication.initialized()) {
      return <TitleBanner title={"Asecla Licenses"}></TitleBanner>;
  }
  return authentication.isAuthenticated() ? children : <HomePage />;
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  const [_, triggerRefresh] = React.useState<boolean>(false);
  ASECLA.Commons.Auth.getInstance().addStateChangeListener((auth: any) => {
    triggerRefresh(true);
  });

  let contextPath = process.env.REACT_APP_CONTEXT_PATH;
  return <QueryClientProvider client={queryClient}>
          <BrowserRouter basename={contextPath}>
            <AseclaDataContextProvider>
              <Routes>
                <Route path={AseclaOfferPage.CONTEXT_PATH} element={<SecurityRoute ><AseclaOfferPage /></SecurityRoute>} />          
                <Route path={ManageUsersPage.CONTEXT_PATH} element={<SecurityRoute ><Priviliges roles={[LicensesRole.admin, LicensesRole.manager]}><ManageUsersPage /></Priviliges></SecurityRoute>} />
                <Route path={ManageOrganizationsPage.CONTEXT_PATH} element={<SecurityRoute ><ManageOrganizationsPage /></SecurityRoute>} />
                <Route path={ManageOneOrganizationPage.CONTEXT_PATH} element={<SecurityRoute><ManageOneOrganizationPage /></SecurityRoute>} />
                <Route path="/" element={<HomePage />} />
                <Route path="/licbis" element={<HomePage />} />
                <Route path={LicensesByOrderPage.CONTEXT_PATH} element={<SecurityRoute ><Priviliges roles={[LicensesRole.manager]}><LicensesByOrderPage /></Priviliges></SecurityRoute>} />
                <Route path={LicensesDetailsPage.CONTEXT_PATH} element={<SecurityRoute ><Priviliges roles={[LicensesRole.manager, LicensesRole.admin]}><LicensesDetailsPage /></Priviliges></SecurityRoute>} />
                <Route path={LicensesListPage.CONTEXT_PATH} element={<SecurityRoute ><Priviliges roles={[LicensesRole.manager, LicensesRole.admin]}><LicensesListPage /></Priviliges></SecurityRoute>} />
                <Route path={LicensesByFeaturePage.CONTEXT_PATH} element={<SecurityRoute ><Priviliges roles={[LicensesRole.manager]}><LicensesByFeaturePage /></Priviliges></SecurityRoute>} />
                <Route path={AseclaAdminProductsPage.CONTEXT_PATH} element={<SecurityRoute ><Priviliges roles={[LicensesRole.aseclaAdmin]}><AseclaAdminProductsPage /></Priviliges></SecurityRoute>} />
                <Route path={AseclaAdminOrganizationsPage.CONTEXT_PATH} element={<SecurityRoute ><Priviliges roles={[LicensesRole.aseclaAdmin]}><AseclaAdminOrganizationsPage /></Priviliges></SecurityRoute>} />
                <Route path={AseclaAdminLicTypePage.CONTEXT_PATH} element={<SecurityRoute ><Priviliges roles={[LicensesRole.aseclaAdmin]}><AseclaAdminLicTypePage /></Priviliges></SecurityRoute>} />
                <Route path={AseclaAdminOrdersPage.CONTEXT_PATH} element={<SecurityRoute ><Priviliges roles={[LicensesRole.aseclaAdmin]}><AseclaAdminOrdersPage /></Priviliges></SecurityRoute>} />
                <Route path={ConfirmOrderPage.CONTEXT_PATH} element={<ConfirmOrderPage />} />
                <Route path={BonusCodesPage.CONTEXT_PATH} element={<SecurityRoute ><Priviliges roles={[LicensesRole.aseclaAdmin]}><BonusCodesPage /></Priviliges></SecurityRoute>} />
                <Route path={RedeemCodePage.CONTEXT_PATH} element={<SecurityRoute><RedeemCodePage/></SecurityRoute>}/>
                <Route path={MyRolePage.CONTEXT_PATH} element={<SecurityRoute><MyRolePage/></SecurityRoute>}/>
              </Routes>
            </AseclaDataContextProvider>
          </BrowserRouter>
    </QueryClientProvider>
};

export default App;

import React from "react";
import AseclaDataContext from "../../../store/AseclaDataContext";
import { ContextProps } from "../../../type/ContextProps";
import { PropertyQuestion } from "../PropertyQuestion";
import { Trans, useTranslation } from 'react-i18next';
import { LicenseType } from "../../../type/LicenseType";
import AseclaButton from "../../utils/AseclaButton";
import { BonusCode } from "../../../type/BonusCode";
import BonusCodeElement from "../../utils/BonusCodeElement";
import UnitDiv from "../../utils/UnitDiv";
import InvoiceData from "../../../type/InvoiceData";
import InvoiceDataForm from "../../InvoiceDataForm";
import LogInOrRegister from "../LogInOrRegister";
import { PAYMENT_METHOD_BANK_TRANSFER } from "../PaymentMethod";
import { useCreateLicenseCodeOrder, useOrderLicenseCode } from "../../../hooks/mutations/useTargetOrder";
import OrderLicenseCodeRequest from "../../../type/request/OrderLicenseCodeRequest";
import CreateOrderRequest from "../../../type/request/CreateOrderRequest";
import styled from "styled-components";
import Stepper from "../../utils/Stepper";
import AseclaProductsColumn from "../AseclaProductColumn";
import i18n from "../../../i18n";
import OrderRaised from "./OrderRaised";
import TitleBanner from "../../utils/TitleBanner";

type ConfirmOrderNewParams = {
    licType?: LicenseType,
    licTypePropValue?: PropertyQuestion[],
    quantity?: number,
    length?: number,
    lengthUnit?: string,
    orderId?: number,

    bonusCodeToForce?: BonusCode|null,
    orderStatus?: string,
    hideBonusCode?: boolean,
}

function ConfirmOrderNew({
            licType,
            licTypePropValue,
            quantity,
            length,
            lengthUnit,
            orderId,
            bonusCodeToForce = null,
            orderStatus,
            hideBonusCode = false,
        }: ConfirmOrderNewParams) {
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;
    const {t, i18n} = useTranslation();
    const [orderSent, setOrderSent] = React.useState<boolean>(false);

    const [shopConsent, setShopConsent] = React.useState<boolean>(false);
    const [useMailForOrderConsent, setUseMailForOrderConsent] = React.useState<boolean>(false);

    const [bonusCodeEnableBuy, setBonusCodeEnableBuy] = React.useState<boolean>(false);
    const [bonusCodeValid, setBonusCodeValid] = React.useState<boolean>(false);
    const [bonusCode, setBonusCode] = React.useState<BonusCode|null>(bonusCodeToForce);

    const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState<string>(PAYMENT_METHOD_BANK_TRANSFER);
    
    const [companyName, setCompanyName] = React.useState<string>("");
    const [taxId, setTaxId] = React.useState<string>("");
    const [address, setAddress] = React.useState<string>("");
    const [city, setCity] = React.useState<string>("");
    const [zipCode, setZipCode] = React.useState<string>("");
    const [country, setCountry] = React.useState<string>("");

    const { createLicenseCodeOrder, isLoading: creatingLicenseCodeOrder } = useCreateLicenseCodeOrder(setOrderSent);
    const { orderLicenseCode, isLoading: orderingLicenseCode } = useOrderLicenseCode(setOrderSent);

    React.useEffect(() => {
        let invoiceData: InvoiceData|undefined = props.getCurrentOrganization()?.invoiceData;
        if (invoiceData != null) {
            setCompanyName(invoiceData.companyName);
            setTaxId(invoiceData.taxId);
            setAddress(invoiceData.address);
            setCity(invoiceData.city);
            setZipCode(invoiceData.zipCode);
            setCountry(invoiceData.country);
        }
    }, [props.getCurrentOrganization()]);

    React.useEffect(() => {
        props.setDisableOrganizationSwitch(orderSent);
        return () => {
            props.setDisableOrganizationSwitch(false);
        };
    }, [orderSent]);

    const orderLicenseCodeClick = (): void => {
        let invoiceData: InvoiceData = {
            companyName: companyName,
            taxId: taxId,
            address: address,
            city: city,
            zipCode: zipCode,
            country: country,
        }
        if (orderId) {
            let req: OrderLicenseCodeRequest = {
                orderId: orderId,
                paymentMethod: selectedPaymentMethod,
                invoiceData: invoiceData,
                organizationId: props.getCurrentOrganization()?.id!,
                language: i18n.language,
                bonusCodeId: (!bonusCodeValid || bonusCode === null) ? null : bonusCode.id,
                bonusCodeValue: (!bonusCodeValid || bonusCode === null) ? null : bonusCode.theCode,
            };
            //If anonymous order was created before or Asecla Admin created an order
            orderLicenseCode(req);
        } else {
            //In case we are filling order request that has not been created on server yet
            let req: CreateOrderRequest = props.pricing.createOrderRequest(
                licTypePropValue, licType!, quantity!, length!, lengthUnit!, invoiceData, selectedPaymentMethod, bonusCodeValid ? bonusCode : null);
            createLicenseCodeOrder(req);
        }
    }

    const enableBuyButton: boolean = (useMailForOrderConsent && shopConsent && bonusCodeEnableBuy);

    if (orderSent) {
        return <OrderRaised msg={t("You order has been sent. Thank you!") as string}></OrderRaised>
    }

    //Other statuses are: Sketch and CreatedForUser
    if (orderStatus === "Raised") {
        return <OrderRaised msg={t("Your order has already been raised. Please await Asecla Team contact or contact us in case of any problems.") as string}></OrderRaised>
    }
    if (orderStatus === "Rejected") {
        return <OrderRaised msg={t("We are sorry, but this order has been rejected. Create new one or contact Asecla Team in case of questions.") as string}></OrderRaised>
    }
    if (orderStatus === "AwaitingPayment") {
        return <OrderRaised msg={t("Your order has already been raised and we are awaiting the payment.") as string}></OrderRaised>
    }
    if (orderStatus === "Paid") {
        return <OrderRaised msg={t("Your order has already been raised and we got the payment. You should receive your license.") as string}></OrderRaised>
    }
    if (orderStatus === "CodeSent") {
        return <OrderRaised msg={t("Your order has been been payed and we have sent you your license. Please contact us in case of any issues.") as string}></OrderRaised>
    }

    const freezePage = (): boolean => {
        return props.freezeHeaderCounter > 0;
    }

    const PaymentMethod = () => {
        let email = props.authentication.getUserMail();
        return <div>
            <h4 className="asecla-heading">{t("Payment method") as string}</h4>
            <div className="configParent">
                <div className="configurationContainer">
                    <span className="controllsSpace">
                        <input
                            type="radio"
                            value={PAYMENT_METHOD_BANK_TRANSFER}
                            checked={selectedPaymentMethod === PAYMENT_METHOD_BANK_TRANSFER}
                            onChange={e => setSelectedPaymentMethod(e.target.value)}
                            id="paymentBankTransfer"
                            name="paymentBankTransfer"
                            disabled={props.freezeHeaderCounter > 0}
                        />
                    </span>
                    <label htmlFor="paymentBankTransfer">{t("Short_Bank Transfer") as string}</label>
                </div>
                {t("PaymentMethodText", {email: email}) as string}
            </div>
        </div>
    }

    if (licType === undefined) {
        return <><h3 className="asecla-heading">{t("Select license you would like to receive") as string}</h3>
            <Trans i18nKey="order_for" components={{ unit: <UnitDiv lengthUnit={"Day"} lowercase={true}></UnitDiv>}} values={{ length: length, quantity: quantity }} />
        </>
    } else {
        if (props.authentication.isAuthenticated()) {
            return <>
                <TitleBanner title={t("Order summary")}></TitleBanner>
                <Stepper stepNo={2} captions={[t("Define your needs"), t("Stepper_Verify"), t("Order")]}></Stepper>
                <div className="OfferContainer">
                    <div className="OfferMenu">
                        <LeftMenu bonusCodeToForce={bonusCodeToForce!} licType={licType!}
                                setBonusCodeEnableBuy={setBonusCodeEnableBuy} setBonusCodeValid={setBonusCodeValid} bonusCode={bonusCode} setBonusCode={setBonusCode} hideBonusCode={hideBonusCode}></LeftMenu>
                    </div>
                    <div className="OfferContent">
                        <div className="menu-placeholder">
                            <LeftMenu bonusCodeToForce={bonusCodeToForce!} licType={licType!}
                                    setBonusCodeEnableBuy={setBonusCodeEnableBuy} setBonusCodeValid={setBonusCodeValid} bonusCode={bonusCode} setBonusCode={setBonusCode} hideBonusCode={hideBonusCode}></LeftMenu>
                        </div>
                        <h3 className="asecla-heading">{t("Invoice data") as string}</h3>
                        <InvoiceDataForm
                            taxId={taxId} setTaxId={setTaxId}
                            companyName={companyName} setCompanyName={setCompanyName}
                            address={address} setAddress={setAddress}
                            city={city} setCity={setCity}
                            zipCode={zipCode} setZipCode={setZipCode}
                            country={country} setCountry={setCountry}
                            validationError={null}
                            readOnly={true}
                        ></InvoiceDataForm>

                        <PaymentMethod/>
                        <hr></hr>
                        <div>
                            <span className="controllsSpace">
                                <input type="checkbox" checked={shopConsent} onChange={e => setShopConsent(e.target.checked)} disabled={freezePage()} name="shopConsent" id="shopConsent"/>*
                            </span>
                            <label htmlFor="shopConsent">{t("I have read and accept the store regulations") as string}</label>
                        </div>

                        <div>
                            <span className="controllsSpace">
                                <input type="checkbox" checked={useMailForOrderConsent} onChange={e => setUseMailForOrderConsent(e.target.checked)} disabled={freezePage()} name="useMailForOrderConsent" id="useMailForOrderConsent"/>*
                            </span>
                            <label htmlFor="useMailForOrderConsent">
                                <Trans i18nKey="use_contact_information"
                                    values={{
                                        toMakeTransaction: t("in order to make the transaction")
                                    }}
                                    components={{bolded: <b></b>}}/>
                            </label>
                        </div>

                        <br></br>
                        {(creatingLicenseCodeOrder || orderingLicenseCode) && <h3 className="asecla-heading" id="processingOrder">{t("We are processing your order, please don't close the browser now") as string}</h3>}
                        {props.freezeHeaderCounter === 0 && <>
                            <span className="controllsSpace"/>
                            <AseclaButton className="cta" action={(e) => orderLicenseCodeClick()} enabled={enableBuyButton}>{t("I'm placing an order") as string}</AseclaButton>
                        </>}
                    </div>
                </div>
            </>;
        } else {
            return <>
                <TitleBanner title={t("Select license you would like to receive")}></TitleBanner>
                <div><Trans i18nKey={"great_choice"} 
                        components={{license: <u></u>,
                            product: <u></u>,
                            units: <UnitDiv lengthUnit={lengthUnit} lowercase={true}></UnitDiv>,
                            props: <>{licTypePropValue && licTypePropValue.length > 0 && 
                                        <>{t("and following properties")}<br></br>{licTypePropValue.map((property) => 
                                            <div key={"property_" + property.propertyId}>
                                                {licType.product.properties[property.productPropertyIndex].labels[i18n.language]}: {property.value}
                                            </div>
                                          )}
                                        </>
                                      }
                                   </>,
                            cost: <b></b>
                        }}
                        values={{length: length,
                                 quantity: quantity,
                                 currency: "zł",
                                 finalCost: licType.price * length! * quantity!,
                                 licenseName: props.getLicenseTypeDisplayName(licType),
                                 productName: !licType.product ? "???" : licType.product.names["en"]}}
                    />
                </div>
                <LogInOrRegister
                    licType={licType}
                    length={length}
                    lengthUnit={lengthUnit}
                    licTypePropValue={licTypePropValue}
                    quantity={quantity}
                ></LogInOrRegister>
            </>
        }
    }
}
export default ConfirmOrderNew;

const LeftMenu = ({bonusCodeToForce, licType, bonusCode, setBonusCode, setBonusCodeEnableBuy, setBonusCodeValid, hideBonusCode}: any) => {
    return <>
        <AseclaProductsColumn columnName={licType!.names[i18n.language]}></AseclaProductsColumn>
        <BonusStyling>
            <BonusCodeElement newLayout={true} bonusCodeToForce={bonusCodeToForce!} licType={licType!}
                            setBonusCodeEnableBuy={setBonusCodeEnableBuy} setBonusCodeValid={setBonusCodeValid} bonusCode={bonusCode} setBonusCode={setBonusCode} includeBonusCodeInput={!hideBonusCode}></BonusCodeElement>
        </BonusStyling>
    </>
}


let BonusStyling = styled.span `
    button {
        width: 90px;
    }
    input {
        width: calc(100% - 90px);
    }
`;
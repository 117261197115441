import { t } from "i18next";
import { LicenseType } from "../../../type/LicenseType";
import { PropertyQuestion } from "../PropertyQuestion";
import React from "react";
import styled from "styled-components";
import { Trans } from "react-i18next";
import Price from "../Price";
import UnitDiv from "../../utils/UnitDiv";
import i18n from "../../../i18n";
import AseclaDataContext from "../../../store/AseclaDataContext";
import { ContextProps } from "../../../type/ContextProps";
import MultipleOrganizationsCheck from "../MultipleOrganizationsCheck";
import AseclaProductsColumn from "../AseclaProductColumn";
import AseclaButton from "../../utils/AseclaButton";
import Stepper from "../../utils/Stepper";
import "./UserOrderWithMenu.css";
import TitleBanner from "../../utils/TitleBanner";

interface UserOrderCustomizeProps {
    setQuantity: (quantity: number) => void,
    setLength: (length: number) => void,
    quantity: number | undefined,
    length: number,
    licType: LicenseType,
    licTypePropValue: Array<PropertyQuestion>,
    setLicTypePropValue: React.Dispatch<React.SetStateAction<Array<PropertyQuestion>>>,
  
    setPropertiesAccepted: React.Dispatch<React.SetStateAction<boolean>>,
    allAvailableLicenses: LicenseType[],
    switchSelectedLicenseTypeTo: (licenseTypeNo : number) => void ,
}
function UserOrderCustomize({setQuantity, setLength, length, quantity, licType, licTypePropValue, setLicTypePropValue,
            setPropertiesAccepted, allAvailableLicenses, switchSelectedLicenseTypeTo}: UserOrderCustomizeProps) {
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;

    const accept = (noOfYears: number) => (e:  React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setLength(noOfYears);
        setPropertiesAccepted(true);
    }

    return <>
        <TitleBanner title={t("Select product")}></TitleBanner>
        <Stepper stepNo={1} captions={[t("Define your needs"), t("Stepper_Verify"), t("Order")]}></Stepper>
        <div className="OfferContainer">
            <div className="OfferMenu">
                <LeftMenu switchSelectedLicenseTypeTo={switchSelectedLicenseTypeTo} allAvailableLicenses={allAvailableLicenses} licType={licType}></LeftMenu>
            </div>
            <div className="OfferContent">
                <div className="menu-placeholder">
                    <LeftMenu switchSelectedLicenseTypeTo={switchSelectedLicenseTypeTo} allAvailableLicenses={allAvailableLicenses} licType={licType}></LeftMenu>
                </div>
                <OfferTable>
                    <thead>
                        <tr><th>{t("Number of Licenses") as string}</th><th>{t("Interval") as string}</th><th>{t("Gross value for protection") as string}<br/>{quantity} {t("users") as string}</th></tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td rowSpan={3}>
                                <input
                                    max={licType.maxQuantity === 0 ? undefined : licType.maxQuantity}
                                    value={quantity}
                                    min={licType.minQuantity === 0 ? 1 : licType.minQuantity}
                                    onChange={e => {if (!isNaN(Number(e.target.value))) setQuantity(Number(e.target.value))}}
                                    id="quantity"
                                    name="quantity"
                                    type="number"
                                    size={5}
                                    />
                            </td>
                            <td>{t("1 year protection") as string}</td>
                            <td><Price length={1} licenseType={licType!} quantity={quantity!} showDiscount={false} showHint={false} priceToCurrencySpace={true}></Price></td>
                            <td><AseclaButton className="small" action={accept(1)}>{t("Select") as string}</AseclaButton></td>
                        </tr>
                        <tr>
                            <td>{t("2+ year protection", {length: "2"}) as string}</td>
                            <td><Price length={2} licenseType={licType!} quantity={quantity!} showDiscount={false} showHint={false} priceToCurrencySpace={true}></Price></td>
                            <td><AseclaButton className="small" action={accept(2)}>{t("Select") as string}</AseclaButton></td>
                        </tr>
                        <tr>
                            <td>{t("2+ year protection", {length: "3"}) as string}</td>
                            <td><Price length={3} licenseType={licType!} quantity={quantity!} showDiscount={false} showHint={false} priceToCurrencySpace={true}></Price></td>
                            <td><AseclaButton className="small" action={accept(3)}>{t("Select") as string}</AseclaButton></td>
                        </tr>
                    </tbody>
                </OfferTable>
                <p>{t("If you have bonuscode, you can apply it in final order step") as string}</p>
                <Paragraph>{t("subscription_aspect") as string}</Paragraph>
                <Paragraph>
                    <Trans i18nKey="how_to_buy_description"
                                    components={{price: <Price length={length} licenseType={licType!} quantity={quantity!} showDiscount={false} showHint={false}></Price>,
                                                unit: <UnitDiv lengthUnit={licType.lengthUnit} lowercase={true}></UnitDiv>,
                                                }}
                                    values={{length: length,
                                            licenseName: licType !== undefined ? licType.names[i18n.language] : "",
                                    }}
                    ></Trans>
                </Paragraph>
                <div>
                    {props.authentication.isAuthenticated() &&
                        <MultipleOrganizationsCheck checkCurrentOrganization={false}><></></MultipleOrganizationsCheck>
                    }
                </div>
            </div>
        </div>
    </>;
}

type LeftMenuParams = {
    allAvailableLicenses: LicenseType[],
    switchSelectedLicenseTypeTo: (licenseTypeNo : number) => void ,
    licType: LicenseType;
}
function LeftMenu({switchSelectedLicenseTypeTo, licType, allAvailableLicenses}: LeftMenuParams) {
    if (allAvailableLicenses === undefined) {
        return <></>
    }
    return <>
        <div className="OneLine">
            <LittleSpan>{t("Selected product")}</LittleSpan>
            <ProductSelect className="small" onChange={(e:React.ChangeEvent<HTMLSelectElement>) => {switchSelectedLicenseTypeTo(Number.parseInt(e.target.value))}} value={licType !== undefined ? licType.id : undefined}>
                        {allAvailableLicenses.map((lic:LicenseType) => 
                            <option key={lic.id} value={lic.id}>{lic.names[i18n.language]}</option>
                        )}
                    </ProductSelect>
        </div>
        {licType && <AseclaProductsColumn columnName={licType.names[i18n.language]}></AseclaProductsColumn>}
    </>
}
export default UserOrderCustomize;

const Paragraph = styled.div`
    margin-bottom: 12px;
`;
const ProductSelect = styled.select`
    width: calc(100% - 60px);
    margin-left: 10px;
`;

const LittleSpan = styled.span`
    font-size: 12px;
    line-height: 100%;
    width: 50px;
    white-space: wrap;
    text-align: left;
    margin-bottom: 15px;
`;
const OfferTable = styled.table`
    width: 100%;
    text-align: center;
`;


import { t } from "i18next";
import Table, { CellProp } from "../utils/Table";
import { LicenseType } from "../../type/LicenseType";
import { ContextProps } from "../../type/ContextProps";
import React from "react";
import AseclaDataContext from "../../store/AseclaDataContext";
import HomeBanner from "../home/HomeBanner";
import { useLicenseTypesOffered } from "../../hooks/useLicenseTypes";
import Loading from "../utils/Loading";
import AseclaProductsTable from "./AseclaProductsTable";
import styled from "styled-components";

interface AseclaOfferProductTableProps {
    onlyVisibleRow: number,
    selectOrderType: (orderNo: number) => void
}
function AseclaOfferProductTable({ onlyVisibleRow, selectOrderType}: AseclaOfferProductTableProps ) {
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;
    const { licenseTypes, isLoading, isError } = useLicenseTypesOffered();

    // let header = [t("License name"), t("Price per person per month"), ""];
    // let body = [];
    // let cellProps: CellProp[][]|undefined = undefined;
    // if (licenseTypes != null) {
    //     for (let i = 0; i < licenseTypes.length; i++) {
    //         if (onlyVisibleRow == -1 || onlyVisibleRow == i) {
    //             let r: LicenseType = licenseTypes[i];
    //             let b = <button className="asecla-button small" onClick={(e) => selectOrderType(i)}>{onlyVisibleRow === -1 ? t("Order") as string : t("Cancel") as string}</button>
    //             let row = [props.getLicenseTypeDisplayName(r), r.price + "zł", b];
    //             body.push(row);
    //         }
    //     }
    // } else {
    //     body.push(isError
    //                 ? [t("Could not contact server. Check your internet connection")]
    //                 : [t("Loading your offer...")]);
    //     cellProps=[[{colSpan: 2}]];
    // }

    return <><HomeBanner></HomeBanner>
            {isLoading && <Loading></Loading>}
            {!isLoading && <>
                    <Spacer></Spacer>
                    <AseclaProductsTable selectOrderType={selectOrderType}></AseclaProductsTable>
                </>
                // <section>
                //     <div id="aseclaOffer">
                //         <h3 className="asecla-heading">{t("Select license you would like to receive") as string}</h3>
                //         <Table headers={header} cellProps={cellProps} tableContent={body}></Table>
                //         <span className="hint">{t("Presented prices are net worth") as string}</span>
                //     </div>
                // </section>
            }
        </>
}
export default AseclaOfferProductTable;

const Spacer = styled.div`
  height:100px;
`;

import React from "react";
import AseclaDataContext from "../store/AseclaDataContext";
import { ContextProps } from "../type/ContextProps";
import { getCollectedLicenses, LicenseStatistics } from "../type/LicenseStatistics";
import { OrganizationData } from "../type/responses/OrganizationData";
import OrganizationResponse from "../type/responses/OrganizationResponse";
import Table from "./utils/Table";
import { useTranslation, Trans } from 'react-i18next';
import AseclaButton from "./utils/AseclaButton";
import { Notifications } from "../integration/Notifications";
import { useAddUserToOrganization, useRemoveUserFromOrganization, useSetRole, useSetUserLicense } from "../hooks/mutations/useOrganizationOperations";
import Loading from "./utils/Loading";
import { useOrganizations } from "../hooks/useOrganizations";
import { useInformUserMail } from "../hooks/queries/useInformUserMail";
import styled from "styled-components";
import AseclaButtonIcon from "./utils/AseclaButtonIcon";
import UserInputActionIcon from "./utils/UserInputActionIcon";
import TitleBanner from "./utils/TitleBanner";

type ManageUsersComponentParams = {
    forcedOrganization?: OrganizationData,
    setForcedOrganization?: (ret: OrganizationResponse) => void,
    editMode?: boolean, setEditMode?: React.Dispatch<React.SetStateAction<boolean>>,
}
function ManageUsersComponent({forcedOrganization: forcedOrganization,
                  setForcedOrganization: setForcedOrganization,
                } : ManageUsersComponentParams) {
    const props = React.useContext(AseclaDataContext) as ContextProps;
    const { t, i18n } = useTranslation();
    const { isAseclaAdmin } = useOrganizations();


    const { warnValidation } = Notifications();
    const { mutate: addUser } = useAddUserToOrganization(forcedOrganization?.id, setForcedOrganization);
    const { mutate: removeUser } = useRemoveUserFromOrganization(forcedOrganization?.id, setForcedOrganization);
    const { mutate: setUserLicense } = useSetUserLicense(forcedOrganization?.id, setForcedOrganization);
    const { mutate: setRole } = useSetRole(forcedOrganization?.id, setForcedOrganization);
    const { data } = useInformUserMail(i18n.language);

    const freezePage = (): boolean => {
        return props.freezeHeaderCounter > 0;
    }

    const getDisplayedOrganization = (): OrganizationData => { 
        if (forcedOrganization) {
            return forcedOrganization;
        }
        return props.getCurrentOrganization()!;
    }

    const switchUserRole = (usrNumber: number, roleName: string, checkbox: any) => {       
        let organization: OrganizationData = getDisplayedOrganization();

        if (!checkbox.checked &&
                organization.users[usrNumber] == props.pageBaseIntegration.getUserDisplayName() &&
                !window.confirm(t("You are about to take yourself a role."))) {
			setTimeout(function() {
			  checkbox.checked = !checkbox.checked;
			}, 0);
			return;
		}

        setRole({roleName, usrToChange: organization.users[usrNumber], setRoleOn: checkbox.checked});
    }

    const removeUserFromOrganization = (usrNo: number) => {
        let usrName: string = getDisplayedOrganization().users[usrNo] as string;

        if (currentOrganization.admins.includes(usrName) && currentOrganization.admins.length == 1) {
            warnValidation(t("You can't remove last Admin. Assign Admin role to the other user before removal."));
            return;
        }

        if (currentOrganization.managers.includes(usrName) && currentOrganization.managers.length == 1) {
            warnValidation(t("You can't remove last Manager. Assign Manager role to the other user before removal"));
            return;
        }

        if (forcedOrganization == undefined && props.authentication.getUserMail() === usrName && !window.confirm(t("You are about to take yourself a role. Are you sure?"))) {
            return;
        } else {
            let userConfirmed = confirm(t("You are about to remove user", {usrName: usrName}));

            if (!userConfirmed) {
                return;
            }
    
        }

        removeUser({userName: usrName});
    }

    const openMailTemplate = () => {
        const subject = data?.subject || "";
        const body = data?.body || "";
        console.log("body", body);
        const mailtoLink = `mailto:?subject=${subject}&body=${body}`;
        window.location.href = mailtoLink;
    }



    if (getDisplayedOrganization() == null) {
        return <Loading></Loading>
    }

    let currentOrganization : OrganizationData = getDisplayedOrganization();

    let headers: any[] = [t("User")];
    let collectedLicenses: Map<number, LicenseStatistics> = getCollectedLicenses(currentOrganization.licenses, true);
    collectedLicenses.forEach((lic: LicenseStatistics) => {
        headers.push(<><div>{lic.licType.names["en"]}</div><div className="hint">({t("used of", {used: (lic.usrs.length), total: lic.availableActive}) as string})</div></>);
    });
    headers.push(t("Admin"));
    headers.push(<Trans i18nKey="Manager"></Trans>)
    headers.push(t("Remove"));

    let usrRows = [];
    for (let i = 0; i < currentOrganization.users.length; i++) {
        usrRows.push({email: currentOrganization.users[i], rowNo: i})
    }

    let classNames = [];
    usrRows = usrRows.sort((a, b) => {
        if (a.email === props.authentication.getUserMail()) return -1;
        if (b.email === props.authentication.getUserMail()) return 1;
        return a.email.localeCompare(b.email);
    });

    let tableContent: any[][] = [];
    for (let i = 0; i < usrRows.length; i++) {
        let usrName: string = usrRows[i].email;
        let rowNo = usrRows[i].rowNo;
        let row: any[] = [];
        
        if (props.authentication.getUserMail() === usrName) {
            tableContent.unshift(row);
            classNames.push("gray");
            row.push(usrName + " (" + t("you") + ")");
        } else {
            tableContent.push(row);
            row.push(usrName);
        }
        collectedLicenses.forEach((lic: LicenseStatistics, licStatIndex: number) => {
            let checked: boolean = (lic["usrs"].indexOf(usrName) != -1);
            let enabled: boolean = checked || lic["usrs"].length < lic.availableActive;
            row.push(<input type="checkbox" checked={checked} disabled={!enabled || freezePage() || (!props.isUserAdmin() && !isAseclaAdmin)}
                onChange={evt => setUserLicense({userName: usrName, licenseId: licStatIndex, turnOn: evt.target.checked})}/>);
        });
        
        let adminChecked: boolean = currentOrganization.admins && currentOrganization.admins.includes(usrName);
        let adminEnabled: boolean = currentOrganization.haveAdminRights && (currentOrganization.admins.length != 1 || !adminChecked);
        row.push(<input type="checkbox" checked={adminChecked} disabled={!adminEnabled || freezePage()}
                onChange={evt => switchUserRole(rowNo, "userAdmin", evt.target)}/>);

        let managerChecked: boolean = currentOrganization.managers && currentOrganization.managers.includes(usrName);
        let managerEnabled: boolean = currentOrganization.haveManagerRights && (currentOrganization.managers.length != 1 || !managerChecked);
        row.push(<input type="checkbox" checked={managerChecked} disabled={!managerEnabled || freezePage()}
                 onChange={evt => switchUserRole(rowNo, "manager", evt.target)}/>);
        
        row.push(<AseclaButton className="small" action={e => removeUserFromOrganization(rowNo)} enabled={!freezePage()}>X</AseclaButton>);
    }

    return <>
        <TitleBanner title={t("Manage users")}></TitleBanner>
        <UserNotice className="hint">{t("AddUserNotice", {inform_users: t("inform_users")}) as string}</UserNotice>
        {currentOrganization.haveAdminRights && 
            <div style={{marginBottom: "70px"}} className="oneliner">
                <UserInputActionIcon
                    buttonIcon={"+"}
                    buttonCaptions={[t("Add user") as string, "E-mail"]}
                    buttonAction={(userName : string) => addUser({userName})} 
                    isValid={(value: string) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)}
                ></UserInputActionIcon>
                <AseclaButtonIcon className="space" icon={"+"} captions={[t("inform_users")]} action={openMailTemplate}></AseclaButtonIcon>
            </div>
        }
        <StyledTableDiv><Table headers={headers} tableContent={tableContent} rowClass={classNames}></Table></StyledTableDiv>
    </>
}

export default ManageUsersComponent;


const UserNotice = styled.div`
    margin-top: 15px;
    margin-bottom: 15px;
`;

const StyledTableDiv = styled.div`
  table {
    --asecla-table-line: 1px solid rgb(201, 201, 201);
  }
  table td:first-child {
    text-align: left;
  }
`;

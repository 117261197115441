import React from "react";
import { ContextProps } from "../../type/ContextProps";
import AseclaDataContext from "../../store/AseclaDataContext";
import AseclaButtonIcon from "./AseclaButtonIcon";

type UserInputActionIconParams = {
    buttonIcon: any,
    buttonCaptions: string[],
    label?: string,
    label2?: string,
    buttonAction: (text: string) => void,
    value?: string,
    onValueChange?: (text: string) => void
    disabled?: boolean,
    isValid?: (text: string) => boolean,
    disableButton?: (text: string) => boolean,
    action?: any,
    placeholder?: string,
    preserveValueOnAction?: boolean
}

function UserInputActionIcon(params: UserInputActionIconParams) {
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;
    const [textValue, setTextValue] = React.useState<string>(params.value ?? "");

    if (params.action) {
        params.action.setTextValue = (text: string) => {setTextValue(text)}
    }
    const freeze = () => {
        return props.freezeHeaderCounter > 0;
    }
    const action = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        params.buttonAction(textValue);
        if (!params.preserveValueOnAction) {
            setTextValue("");
        }
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTextValue(e.target.value);
        if (params.onValueChange) {
            params.onValueChange(e.target.value);
        }
    }

    const styles = {
        color: (params.isValid == undefined || params.isValid!(textValue)) ? "black" : "red",
    };

    const disableButtonFun = (): boolean => {
        if (params.disableButton && params.disableButton(textValue)) {
            return true;
        }
        return freeze() || (params.disabled ?? false);
    }

    return <>
        {params.label ?? ""}
        <input
            className="aseclainput"
            placeholder={params.placeholder}
            value={params.onValueChange == undefined ? textValue : params.value}
            style={styles}
            onChange={onChange}
            disabled={freeze() || (params.disabled ?? false)}
            onKeyDown={(e) => {if (e.key === 'Enter') action(undefined)}}
        />
        {params.label2 ?? ""}
        <AseclaButtonIcon icon={params.buttonIcon} captions={params.buttonCaptions} action={action} disableGUI={disableButtonFun()}></AseclaButtonIcon>
    </>
}

export default UserInputActionIcon;

import { t } from "i18next";
import styled from "styled-components";

type AseclaProductsColumnParams = {
    columnName: string
}
function AseclaProductsColumn({columnName}: AseclaProductsColumnParams) {
    return <>
        <PaddingDiv className="product_pricing_table">
            {columnName === "Silver" &&
                <div className="column column2">
                    <div className="wordField tableheader">Silver</div>
                    <div className="wordField lightWordField">{t("You send occasionally") as string}</div>
                    <div className="wordField lightWordField">{t("File up to 50 GB") as string}</div>
                    <div className="wordField lightWordField bitCrowdedField">{t("Full E2E encryption (AES-256)") as string}</div>
                    <div className="wordField lightWordField bitCrowdedField">{t("Downloading without creating an account") as string}</div>
                </div>
            }
            {columnName === "Gold" &&
                <div className="column column3">
                    <div className="wordField tableheader">Gold</div>
                    <div className="wordField lightWordField">{t("You send regularly") as string}</div>
                    <div className="wordField lightWordField">{t("File up to 200 GB") as string}</div>
                    <div className="wordField lightWordField bitCrowdedField">{t("Full E2E encryption (AES-256)") as string}</div>
                    <div className="wordField lightWordField bitCrowdedField">{t("Downloading without creating an account") as string}</div>
                </div>
            }
            {columnName === "Platinum" &&
                <div className="column column4">
                    <div className="wordField tableheader">Platinum</div>
                    <div className="wordField lightWordField">{t("You are sending intensive") as string}</div>
                    <div className="wordField lightWordField">{t("File up to 500 GB") as string}</div>
                    <div className="wordField lightWordField bitCrowdedField">{t("Full E2E encryption (AES-256)") as string}</div>
                    <div className="wordField lightWordField bitCrowdedField">{t("Downloading without creating an account") as string}</div>
                </div>
            }
        </PaddingDiv>
    </>
}
export default AseclaProductsColumn;

const PaddingDiv = styled.div`
    div.column.column2,
    div.column.column3,
    div.column.column4 {
        margin-left: 0px;
        margin-right: 0px;
    }
    
    div.column div.bitCrowdedField {
        line-height: 20px;
    }

    div.column div.bitCrowdedField {
        @media (max-width: 768px) {
            font-size: clamp(16px, 3vw, 20px);
        }
    }
`;
